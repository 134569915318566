exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-readmore-js": () => import("./../../../src/pages/readmore.js" /* webpackChunkName: "component---src-pages-readmore-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/CategoryTemplate.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-image-carousel-template-js": () => import("./../../../src/templates/ImageCarouselTemplate.js" /* webpackChunkName: "component---src-templates-image-carousel-template-js" */),
  "component---src-templates-masonry-gallery-template-js": () => import("./../../../src/templates/MasonryGalleryTemplate.js" /* webpackChunkName: "component---src-templates-masonry-gallery-template-js" */)
}

